import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuardNonAdmin } from './auth/aut-guard-nonadmin.service';
import { AuthGuard } from './auth/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch:  'full',
      },
      {
        path: 'ashik-dashboard',
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./ecampus/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'masters',
        loadChildren: () => import('./ecampus/masters/masters.module').then(module => module.MastersModule)
      },
      {
        path: 'pre-admission',
        loadChildren: () => import('./ecampus/admission/admission.module').then(module => module.AdmissionModule)
      },
      {
        path: 'student',
        loadChildren: () => import('./ecampus/student/student.module').then(module => module.StudentModule)
      },
      {
        path: 'institution',
        loadChildren: () => import('./ecampus/institution/institution.module').then(module => module.InstitutionModule)
      },
      {
        path: 'Fee',
        loadChildren: () => import('./ecampus/fees/fees.module').then(module => module.FeesModule)
      },
      {
        path: 'attendance',
        loadChildren: () => import('./ecampus/attendance/attendance.module').then(module => module.AttendanceModule)
      },
      {
        path: 'media',
        loadChildren: () => import('./ecampus/media/media.module').then(module => module.MediaModule)
      },
      {
        path: 'employee',
        loadChildren: () => import('./ecampus/employee/employee.module').then(module => module.EmployeeModule)
      },
      {
        path: 'accounts',
        loadChildren: () => import('./ecampus/accounts/accounts.module').then(module => module.AccountsModule)
      },
      {
        path: 'ExamManagement',
        loadChildren: () => import('./ecampus/exam-management/exam-management.module').then(module => module.ExamManagementModule)
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    canActivate: [AuthGuardNonAdmin],
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./ecampus/authentication/authentication.module').then(module => module.AuthenticationModule)
      },
      {
        path: 'pages',
        loadChildren: () => import('./ecampus/pages/pages.module').then(module => module.PagesModule)
      },
      // {
      //   path: 'ashik-auth',
      //   loadChildren: () => import('./demo/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      // },
      // {
      //   path: 'maintenance',
      //   loadChildren: () => import('./demo/pages/maintenance/maintenance.module').then(module => module.MaintenanceModule)
      // }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    // { relativeLinkResolution: 'legacy' }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
